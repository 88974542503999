@import url('https://fonts.googleapis.com/css2?family=Afacad:ital,wght@0,400..700;1,400..700&display=swap');

body,
html {
  background-color: #FFFFFF;
  height: 100%;
  font-family: "Afacad", sans-serif;
  font-optical-sizing: auto;
}

body {
  padding-top: 56px;
  padding-bottom: 56px;
}

.b-1 {
  border: 1px solid red;
}

.w-1 {
  width: 64px;
}
.w-2 {
  width: 128px;
}
.w-3 {
  width: 256px;
}
.w-4 {
  width: 512px;
}

.switch-danger > .form-check-input:checked {
  background-color: #dc3545;
  border-color: #dc3545;
}

.switch-danger > .form-check-input:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.switch-success > .form-check-input:focus {
  border-color: #99C991;
  box-shadow: none;
  /* box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); */
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%28153, 201, 145, 0.5%29%27/%3e%3c/svg%3e");
}

.switch-success > .form-check-input:checked {
  background-color: #99C991;
  border-color: #99C991;
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
}

.accordion-header > button {
  padding-top: 4px;
  padding-bottom: 4px;
}

/* Star Rating */
.star {
  color: #ff9529;
}
.star:not(.disabled) {
  cursor: pointer;
}
.star-rating:hover span:not(.disabled) {
  filter: drop-shadow(0px 0px 2px #ff9529);
}

.star:not(.disabled):hover,
.star:not(.disabled):hover ~ span {
  filter: drop-shadow(0px 0px 0px #fdcc0d);
}

.tooltip-text {
  position: absolute;
  top: -40px;
  left: -50%;
  z-index: 2;
  width: 150px;
  color: white;
  font-size: 10px;
  background-color: #192733;
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
  opacity: 0;
  transition: opacity 0.5s;
}

.hover-text:hover .tooltip-text {
  opacity: 1;
}

.hover-text {
  position: relative;
  display: inline-block;
  font-family: Arial;
  text-align: center;
}

.user-title {
  font-size: small;
  font-weight: bold;
  color: #f3f3f4;
  position: relative;
  left: -16px;
  display: inline;
  padding: 5px 10px;
}
.chat-admin {
  border-left: 12px solid rgb(220, 53, 69) !important;
}
.chat-admin .user-title {
  background-color: rgb(220, 53, 69) !important;
}
.chat-business {
  border-left: 12px solid rgb(255, 193, 7) !important;
}
.chat-business .user-title {
  background-color: rgb(255, 193, 7) !important;
}
.chat-consumer {
  border-left: 12px solid rgb(13, 202, 240) !important;
}
.chat-consumer .user-title {
  background-color: rgb(13, 202, 240) !important;
}
.chat-agent {
  border-left: 12px solid rgb(25, 135, 84) !important;
}
.chat-agent .user-title {
  background-color: rgb(25, 135, 84) !important;
}
.chat-other {
  border-left: 12px solid rgb(13, 110, 253) !important;
}
.chat-other .user-title {
  background-color: rgb(13, 110, 253) !important;
}

.sticky-note {
  background-color: rgba(255, 193, 7, 0.2) !important;
}

/* Loader  */
.loader {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  margin:15px auto;
  position: relative;
  color: #4fb2eb;
  box-sizing: border-box;
  animation: animloader 2s linear infinite;
}

@keyframes animloader {
  0% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
  }
  25% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 2px;
  }
  50% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 2px,  -38px 0 0 -2px;
  }
  75% {
    box-shadow: 14px 0 0 2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
  }
  100% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
  }
}

.form-control:focus {
  border-color: unset;
  box-shadow: unset;
}

.madevo-secondary {
  color: #F20040 !important;
}

.madevo-hover:hover, .madevo-sucess {
  background-color: #99C991;
}

.madevo-sidebar, .assistant-msg {
  background-color: #F7ECEC;
}

/* Sidebar */
.wrapper {
    display: flex;
    align-items: stretch;
}

#sidebar {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 184px;
    max-width: 184px;
    z-index: 1039;
    background-color: #F7ECEC;
    height: 100vh;
}

#sidebar .side-buttons {
  margin-top: 80px;
}

#sidebar .side-buttons > .nav-link {
  margin-top: 40px;
}

#sidebar .side-buttons > .nav-link.active > .side-button {
  box-shadow: 2px 2px 4px rgba(0,0,0,0.15) inset;
}

#sidebar .side-buttons > .nav-link > .button-title,
.madevo-text-secondary {
  color: #777777;
}

#sidebar .side-button {
  background-color: #FFFFFF;
  width: 51px;
  height: 50px;
  padding-top: 10px;
  border-radius: 10px;
  box-shadow: 2px 2px 4px rgba(0,0,0,0.15);
}

#sidebar.active {
    margin-left: -184px;
}

.main-container {
  position: absolute;
  left: 184px;
  width: calc(100vw - 184px);
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.main-container-assistant {
  max-width: unset;
}

.assistant-container {
  max-width: unset;
  padding-left: unset !important;
}

.madevo-card {
  border: 1px solid #B9B9B9;
  border-radius: 5px;
  background-color: #F5F5F5;
}

.madevo-btn-green:hover {
  background-color: #99C991 !important;
}

.madevo-badge {
  background-color: #F20040 !important;
  font-size: 9pt;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  font-weight: bold;
}

.list-action {
  font-size: 23px;
  padding: 2px;
  margin-right: 8px;
  color: #777777;
}

.list-action:hover {
  color: #000000;
}

.list-action.disabled {
  color: #C3C3C3;
}

.list-action.red-action:hover {
  color: #F20040;
}

.text-small {
  font-size: 10pt;
}

.modal-header {
  border-bottom: unset;
}

.modal-footer {
  border-top: unset;
}

.nav-tabs .nav-link {
  isolation: isolate;
  color: #777777;
  background-color: #FCFCFC;
  border-color: #C3C3C3;
  border: 1px solid #C3C3C3;
  border-bottom: unset;
}

.nav-tabs .nav-link.active {
  color: #777777;
  background-color: #F5F5F5;
  border-color: #C3C3C3;
  border-bottom: 2px solid #F5F5F5;
}

.tab-body-container {
  background-color: #F5F5F5;
  border-color: #C3C3C3 !important;
}

.accordion-button.collapsed {
  background-color: #FFFFFF;
  box-shadow: none;
}
.accordion-button:not(.collapsed) {
  background-color: #E6E6E6;
  box-shadow: none;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #C3C3C3;
  outline: 0;
  box-shadow: none;
}

.btn-close:focus {
  outline: 0;
  box-shadow: none;
  opacity: 1;
}

.form-check-input:checked {
  background-color: #99C991;
  border-color: #99C991;
}
.form-check-input:focus {
  border-color: #99C991;
  outline: 0;
  box-shadow: none;
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e");
}

.new-btn {
  padding-top: 4px;
}
.new-btn-plus {
  margin-top: -2px;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  font-size: 12pt;
}

.btn-success {
  background-color: #99C991;
  border: none;
}

.btn-danger, .bg-danger {
  background-color: #F20040 !important;
  border: none;
}

.nav-item.active > a,
.nav-item.active > a:focus,
.navbar-nav .nav-link.show,
.navbar-nav .nav-link.active  {
  color: #F20040;
}

.file-input {
  border: 2px dashed #C3C3C3;
  font-size: 24pt;
  color: #C3C3C3;
  width: 100%;
}

.file-input.file-input-over {
  border: 2px dotted #777777;
}

/* Assistant  */
.conversation-container {
  transition: 0.5s;
  position: fixed;
  top: 121px;
  right: 0;
  max-width: 300px;
}

.conversation-container .madevo-card {
  border-radius: 5px 0 0 5px;
}

.conversation-container.closed {
  right: -250px;
  transition: 0.5s;
}

.conversation-container .controls {
  margin: 1rem;
  position: relative;
  width: 20px;
}
.conversation-container .conv-list {
  position: relative;
  width: 280px;
  height: calc(100vh - 312px);
  max-height: calc(100vh - 312px);
  overflow-y: scroll;
  margin-top: 10px;
}
.conversation-container .conv-list .my-1 {
  margin-top: 0px !important;
  margin-bottom: 2px !important;
}

.conversation-container .conv-list .my-1 > button {
  border-radius: 0;
}

.conversation-container #conversation-icon {
  width: 20px;
  height: 20px;
}

.icon-small {
  font-size: 12pt;
}

.chat-history {
  /* height: 600px; */
  height: calc(100vh - 320px);
}

.chat-history-summary {
  height: calc(100vh - 280px);
  overflow: auto;
}

.chat-history-summary > ul {
  list-style-type: none;
  padding-left: 0px;
}

.chat-history-summary > ul > li {
  border: 1px solid #ffffff;
  background: #e8e6e6;
  padding: 5px;
}

.nav-header-shadow { 
  background: #FFF;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
}